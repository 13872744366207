<template>
  <a-layout-header class="layout-header">
    <a-row>
      <a-col :span="24">
        <a-button
          class="header-menu-btn"
          type="link" shape="circle"
          @click="appStore.collapsed=!appStore.collapsed"
        >
          <template #icon>
            <span class="mdi mdi-menu" :style="{ color: '#212121', fontSize: '1.5rem' }"></span>
          </template>
        </a-button>
        
        <div class="header-title">知識學堂：發布文章</div>
      </a-col>
    </a-row>
  </a-layout-header>

  <a-layout-content class="layout-content">
    <div class="app-forum-editor-page">
      <div class="__wrapper">
        <a-card class="editor-card" :bordered="false">
          <a-input
            v-model:value="formState.title"
            :bordered="false"
            size="large"
            placeholder="標題"
            :style="{ fontSize: '1.6rem', lineHeight: '3rem', marginBottom: '8px', paddingLeft: '0' }"
          />

          <div class="editor-category__actions">
            <a-dropdown trigger="click">
              <a-button
                class="action-btn"
                :class="{ 'chosen': formState.category != null }"
                type="text"
              >
                <template v-if="formState.category == null">
                  選擇發布看板 <span class="mdi mdi-chevron-down ml-2" />
                </template>
                
                <template v-else>
                  {{ formState.category.name }}
                </template>
              </a-button>

              <template #overlay>
                <a-menu>
                  <a-menu-item
                    v-for="catItem in categories"
                    @click="chooseCategory(catItem)"
                  >
                    {{ catItem.name }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
          
          <a-divider class="mb-2"></a-divider>

          <TiptapEditor
            v-model="formState.content"
          />

          <div class="editor-footer__actions" align="right">
            <a-button class="mr-2" type="text" @click="showCancelConfirm">取消</a-button>
            <a-button
              v-if="mode == 'publish'"
              type="primary"
              @click="showPublishConfirm"
            >發文</a-button>
            <a-button
              v-if="mode == 'edit'"
              type="primary"
              @click="showEditedConfirm"
            >更新文章</a-button>
          </div>
        </a-card>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { defineComponent, createVNode, ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { message, Modal } from 'ant-design-vue'
import { useAuthStore } from '@/store/auth'
import { useAppStore } from '@/store/app'
import api from '@/apis'
import TiptapEditor from '@/components/common/TiptapEditor/index.vue'

export default defineComponent({
  components: {
    TiptapEditor
  },
  setup() {
    const route = useRoute();

    /* Store */
    const authStore = useAuthStore();
    const appStore = useAppStore();

    /* Form State */
    const mode = ref('publish'); // publish|edit
    const initialState = {
      category: null,
      title: '',
      content: '',
    }
    const formState = reactive({ ...initialState });

    /* Categories */
    const categories = ref([]);
    const chooseCategory = ((catItem) => {
      formState.category = catItem;
      console.log(formState.category)
    });

    /* Api */
    const getCategories = (async () => {
      const response = await api.v1.forum.categoryList();
      categories.value = response.data.result;
      console.log(categories.value)
    });

    const getArticleInfo = (async (article_id) => {
      const response = await api.v1.forum.artcileInfo(article_id);
      return response.data.result
    });

    /* Mounted */
    onMounted(async () => {
      getCategories();

      if (route.query.id) {
        mode.value = 'edit';
        const articleData = await getArticleInfo(route.query.id);
        formState.category = articleData.category;
        formState.title = articleData.title;
        formState.content = articleData.content;
      }
    });

    return {
      route,

      /* Store */
      authStore,
      appStore,

      /* Form State */
      mode,
      formState,

      /* Billboard Categories */
      categories,
      chooseCategory
    }
  },
  methods: {
    /* Confirm Dialog */
    showCancelConfirm() {
      const _this = this;
      const text = '編輯';
      
      Modal.confirm({
        title: `確認取消${text}文章？`,
        content: createVNode('div', { style: 'color: #424242;' }, `您撰寫的內容不會進行保留，取消後即返回知識學堂頁面`),
        okText: `取消${text}`,
        cancelText: '關閉',
        onOk() {
          _this.$router.push({ name: 'AppForum' });
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    },
    showPublishConfirm() {
      const err = this._checkRequried();
      if (err) {
        message.error(err);
        return
      }

      const _this = this;
      Modal.confirm({
        title: `確認將 ${this.formState.title} 發布至知識學堂？`,
        content: createVNode('div', { style: 'color: #424242;' }, `看板分類：${this.formState.category.name}`),
        async onOk() {
          await _this.insertOneArticle();
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    },
    showEditedConfirm() {
      const err = this._checkRequried();
      if (err) {
        message.error(err);
        return
      }

      const _this = this;
      Modal.confirm({
        title: `確認更新文章內容？`,
        content: createVNode('div', { style: 'color: #424242;' }, `看板分類：${this.formState.category.name}`),
        async onOk() {
          await _this.updateOneArticle();
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    },

    /* Private func */
    _checkRequried() {
      let err = '';
      
      if (!this.formState.title) {
        err = '請輸入文章標題';
      } else if (this.formState.category==null || Object.keys(this.formState.category).length == 0) {
        err = '請選擇文章的看板分類';
      } else if (!this.formState.content) {
        err = '請輸入文章內容';
      } 

      return err
    },

    /* Funcs */
    async insertOneArticle() {
      try {
        await api.v1.forum.insertOneArtcile({
          category_id: this.formState.category.id,
          title: this.formState.title,
          content: this.formState.content
        });

        message.success('發布文章成功');
        this.$router.push({ name: 'AppForum' });
      } catch (error) {
        message.error('發布文章發生錯誤，請稍後再試');
        console.log(error);
      }
    },
    async updateOneArticle() {
      try {
        await api.v1.forum.updateOneArticle(
          this.route.query.id,
          {
            category_id: this.formState.category.id,
            title: this.formState.title,
            content: this.formState.content
          }
        );
        
        message.success('更新文章成功');
        this.$router.push({ name: 'AppForum' });
      } catch (error) {
        message.error('更新文章發生錯誤，請稍後再試');
        console.log(error);
      }
    }
  }
})
</script>

<style lang="scss">
.app-forum-editor-page {
  .__wrapper {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }

  .editor-card {
    .ant-card-body {
      padding: 24px 36px 4px 36px;
    }

    .editor-category__actions {
      margin-bottom: 24px;

      .action-btn {
        color: #424242;
        background: #f6f6f6;

        &.chosen {
          color: white;
          background: #1890ff;
        }
      }
    }

    .editor-footer__actions {
      height: 50px;
    }
  }
}
</style>